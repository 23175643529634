import React from "react";
import styled from "styled-components"
import tw from "twin.macro";
import watch from "../icons/watch_icon.png"
import shoe from "../icons/shoe_icon.png"
import generic from "../icons/generic.png"
import { formatDate, mainBackground, secondaryBackground, secondaryText } from "../const";
import nike from "../icons/img01.jpg"
import nike2 from "../icons/img02.jpg"

const TransactionBox = styled.div`
  ${tw`
      flex
      flex-col
  `}
  color: ${secondaryText};
`

const Card = styled.div`
  ${tw`
      flex
      flex-col
  `}
  border-radius: 15px;
  border: 1px outset ${secondaryText};
  margin-bottom: 20px;
`

const Buttons = styled.div`
  @keyframes appear{
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }

  ${tw`
      flex
      w-full
  `}

  animation: appear 300ms ease-in-out;

  button{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #00dada;
    padding: 5px 0px;
  }

  .leftButton{
    border-bottom-left-radius: 15px;
    border-right: 1px solid ${mainBackground};
  }

  .rightButton{
    border-bottom-right-radius: 15px;
    border-left: 1px solid ${mainBackground};
  }

  .onlyButton{
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
`

const Transaction = styled.div`
  ${tw`
      flex
  `}
  align-items: center;
  height: 61px;
  width: 100%;
`

const TransactionDesc = styled.div`
  ${tw`
      flex
      flex-col
      ml-2
  `}

  width: 50%;
  .item_name{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-height: 1.1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    font-size: 14px;
    width: 90%;
    margin-bottom: 5px;
  }

  .item_date{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 10px;
  }
`

const TransactionPrice = styled.div`
  ${tw`
      flex
      flex-col
  `}
  align-items: center;
  font-size: 15px;

  .price-text{
    font-size: 10px;
  }

  .sellPrice{
    font-size: 14px;
    color: #61DE70;
  }

  .buyPrice{
    font-size: 14px;
    color: #de6161;
  }
`

const ShoeIconContainer = styled.div`
  width: 80px;
  height: 60px;
  border-top-left-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 50px; 
    height: 50px;
  }
`

const WatchIconContainer = styled.div`
  width: 80px;
  height: 60px;
  border-top-left-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 50px; 
    height: 50px;
  }
`

const GenericIconContainer = styled.div`
  width: 80px;
  height: 60px;
  border-top-left-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 40px; 
    height: 40px;
  }
`

const DatabaseShoe = styled.img`
  width: 80px;
  height: 60px;
  border-top-left-radius: 15px;
  ${props => !props.roundImg && 'border-bottom-left-radius: 15px;'}
`

const UrlImageContainer = styled.div`
    width: 80px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
      max-width: 80px;
      max-height: 60px;
    }
`

export default function ItemWarehouse(props){
  const {item, sales, index, infoFunc, sellFunc} = props;
  const {name, type, buyPrice, sellPrice, expectedSellPrice, buyDate, sellDate, imageUrl} = item;

  const [show, setShow] = React.useState(false);

  function showButtons(){
    setShow(!show);
  }

  function showInfoCard(){
    infoFunc(item);
  }
  
  if(imageUrl){
    console.log(imageUrl)
  }
  return(
    <TransactionBox>
    {
      sales ? (
        <Card>
          <Transaction id={index} key={index} roundImg={show} onClick={showInfoCard}>
            {imageUrl ? 
               <UrlImageContainer>
                <img src={imageUrl} alt="itemImage" />
               </UrlImageContainer> : 
              <div>
                {item.type == "shoe" && !item.sneaker && <ShoeIconContainer>
                  <img src={shoe} />
                </ShoeIconContainer>}
                {item.sneaker && 
                <DatabaseShoe src={item.sneaker.ShoeImage} />
                }
                {item.type == "watch" && <WatchIconContainer>
                  <img src={watch} />
                </WatchIconContainer>}
                {item.type == "other" && <GenericIconContainer>
                  <img src={generic} />
                </GenericIconContainer>}
              </div>
            }
            <TransactionDesc>
              <span className="item_name">{item.name}</span>
              <span className="item_date">Data: {formatDate(item.sellDate)} {item.shoe && ` (${item.shoe.sizeEU} ${item.shoe.gender.slice(0,3)})`}</span>
            </TransactionDesc>
            <TransactionPrice>
              <span className="price-text">Vendita</span>
              <span className="sellPrice">+{item.sellPrice}€</span>
            </TransactionPrice>
          </Transaction>
        </Card>
      ) : (
        <Card>
          <Transaction id={index} key={index} roundImg={show} onClick={showInfoCard}>
          {imageUrl ? 
               <UrlImageContainer>
               <img src={imageUrl} alt="itemImage" />
              </UrlImageContainer> : 
              <div>
                {item.type == "shoe" && !item.sneaker && <ShoeIconContainer>
                  <img src={shoe} />
                </ShoeIconContainer>}
                {item.sneaker && 
                <DatabaseShoe src={item.sneaker.ShoeImage} />
                }
                {item.type == "watch" && <WatchIconContainer>
                  <img src={watch} />
                </WatchIconContainer>}
                {item.type == "other" && <GenericIconContainer>
                  <img src={generic} />
                </GenericIconContainer>}
              </div>
            }
            <TransactionDesc>
              <span className="item_name">{item.name}</span>
              <span className="item_date">Data: {formatDate(item.buyDate)} {item.shoe && ` (${item.shoe.sizeEU} ${item.shoe.gender.slice(0,3)})`}</span>
            </TransactionDesc>
            <TransactionPrice>
              <span className="price-text">Acquisto</span>
              <span className="buyPrice">-{item.buyPrice}€</span>
            </TransactionPrice>
          </Transaction>
        </Card>)
    }
    </TransactionBox>
  )
}
