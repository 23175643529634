import React, { useRef } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ApolloClient, InMemoryCache, ApolloProvider, gql, HttpLink, ApolloLink} from '@apollo/client'
import { BrowserRouter} from 'react-router-dom';
import cookies from './help/cookie';
import { useEffect } from 'react';
import { GlobalStateProvider } from './GlobalStateContext.jsx';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const authLink = new ApolloLink((operation, forward) => {
  const accessToken = cookies.get('accessToken');
  operation.setContext({
    headers: {
      authorization: accessToken ? `Bearer ${accessToken}` : '',
      'apollo-require-preflight': 'true',
    },
  });
  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(createUploadLink({ uri: process.env.REACT_APP_URI })),
  cache: new InMemoryCache(),
})

function ApolloProviderWithAuthorization() {
  return (
    <ApolloProvider client={client}>
      <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
      </React.StrictMode>
    </ApolloProvider>
  );
}



root.render(
  <GlobalStateProvider>
    <ApolloProviderWithAuthorization />
  </GlobalStateProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
