import React, { Fragment, useCallback } from "react";
import styled from "styled-components"
import tw from "twin.macro";
import Navfixed from "../components/Navfixed";
import './css_files/WarehousePage.css'
import { Dialog, RadioGroup, Tab, Transition } from "@headlessui/react"
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import MainButton from "../components/MainButton";
import { useNavigate } from "react-router-dom";
import roundAlert from "../icons/roundAlert.png";
import ItemField from "../components/itemField";
import ItemWarehouse from "../components/ItemWarehouse";
import { formatDate, mainBackground, secondaryText, secondaryBackground, mainFucsia, loginInput, longInput, shoeSize,  } from "../const";
import nike from "../icons/img01.jpg"
import HeaderFixed from "../components/HeaderFixed";
import LoadingAnimation from "../components/LoadingAnimation";
import CDatePicker from "../components/CDatePicker";
import TypesFiltering from "../components/TypesFiltering";
import './css_files/WalletPage.css'
import Slider from '@mui/material/Slider'
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import filterIcon from '../icons/white_filt.png';
import { FormRow, OptionsContainer, OptionWareIcon, OptionText, OptionWare } from "../styledElements";
import FormField from "../components/FormField";
import CSelector from "../components/CSelector";
import { GRAPHQL_MAX_INT } from "graphql";
import watch from "../icons/watch_icon.png"
import shoe from "../icons/shoe_icon.png"
import generic from "../icons/generic.png"
import { actionTypes, sellActionTypes, useGlobalState } from "../GlobalStateContext"
import ErrorMessage from "../components/ErrorMessage";
import ErrorPopUp from "../components/ErrorPopUp";
import CorrectPopUp from "../components/CorrectPopUp";
import ChangeItem from "../components/ChangeItem";
import back_arrow from "../icons/back_arrow.png"
import size_icon from "../icons/ruler.png"
import delete_red from "../icons/delete_red.png"
import undo_transaction from "../icons/undo_transaction.png"
import resell_icon from "../icons/redo_sale.png"
import edit_icon from "../icons/edit_icon.png"
import sell_icon from "../icons/sell_icon.png"
import NewItemForm from "../components/NewItemForm";
import PrintItemList from "../components/PrintItemList";

const Arrow = styled.div`
  width: 25px;
  height: 25px;
  z-index: 2;
`

const RowDiv = styled.div`
    ${tw`
      flex
      items-center
    `}
    margin-top: 10px;
  margin-left: 20px;
  margin-bottom: 20px;
`

const RegistrationText = styled.span`
  ${tw`
      text-white
  `}
  width: ${longInput};
  font-size: 22px;
  font-weight: 600;
  margin-left: 15px;
`

const FiltersContainer = styled.div`
  ${tw`
      w-full
      flex
      justify-center
      mb-2
  `}
`

const TypesContainer = styled.div`
  ${tw`
      flex
      flex-row
      w-full
      justify-around
  `}
`

const ShowFilters = styled.div`
  ${tw`
      flex
      items-center
      justify-center
      p-2
      flex-row
      mb-2
  `}
  font-size: 16px;
  color: white;
  width: 70%;
  border-radius: 12px;
  border: 2px solid black;
  ${props => !props.active ? 'box-shadow: 0px 1px 1px #00b6b6;' : ''};
  background: transparent;
  
  img{
    margin-left: 10px;
    width: 18px;
    height: 18px;
  }
`

const SliderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-inline: 10px;
  margin-bottom: 15px;
`

const InputContainer = styled.div`
  ${tw`
      flex
      flex-col
      items-center
  `}
  width: 45%;

  span{
    color: white;
    font-size: 14px;
  }
`

const InputIconContainer = styled.div`
  ${tw`
      relative
      mt-2
      flex
      items-center
      w-full
  `}
`

const IconNumberContainer = styled.div`
  ${tw`
      absolute
      inset-y-0
      left-0
      flex
      items-center
      px-2
  `}
  background: black;
  border-bottom-left-radius: 10px;
  border-bottom: 2px solid white;
  border-top-left-radius: 10px;

  img{
    width: 20px;
    height: 20px;
  }
`

const NumberInput = styled.input`
  ${tw`
      py-2
      pl-12
  `}
  width: 100%;
  background: transparent;
  color: white;
  border-bottom: 2px solid white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  &:focus{
    outline: none;
  }
  `


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const NoItems = styled.div`
  @keyframes appear{
    0%{
      transform: TranslateY(20px);
      opacity: 0;
    }
    100%{
      transform: TranslateY(0px);
      opacity: 1;
    }
  }

  @keyframes shake {
    0% { transform: translateX(0); }
    20% { transform: translateX(-10px); }
    40% { transform: translateX(10px); }
    60% { transform: translateX(-10px); }
    80% { transform: translateX(10px); }
    100% { transform: translateX(0); }
  }

  ${tw`
      flex
      flex-col
      text-white
      font-semibold
      justify-center
      items-center
  `}

  margin-top: 30px;

  animation: appear 0.5s ease-in-out;

  span {
    font-size: 14px;
  }

  img{
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    animation: shake 0.5s ease-in-out 0.5s;
  }
`

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 90%;
  background: black;
  padding: 18px 10px;
  border-radius: 15px;
  text-align: center
`

const InfoContainer = styled.div`
  ${tw`
      text-white
      py-4
      flex
      flex-col
      items-center
  `}
  width: 80%;
  position: fixed;
  top: 10%;
  border-radius: 15px;
  background: ${mainBackground};

  .generalIcon{
    margin-top: 10px;
    width: 50%;
  }

  .iconImage{
    width: 50px;
    height: 50px;
  }

  .itemImage{
    max-width: 150px;
    max-height: 150px;
  }

  .itemName{
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 80%;
    word-wrap: break-word;
  }
`

const CenterContainer = styled.div`
  ${tw`
      w-full
      flex
      justify-center
  `}
`

const InfoRow = styled.div`
  ${tw`
      flex
      justify-between
  `}
  font-size: 14px;
  width: 70%;
  margin-bottom: 8px;
`

const InfoGroupTitle = styled.div`
  font-size: 16px;
  width: 80%;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: left;
`

const TypeFilters = styled.div`
  ${tw`
      mt-2
      flex
      flex-col
  `}
`

const ChoiceContainer = styled.div`
${tw`
  flex
  flex-col
  items-center
  fixed
  `}
  border: 1px solid #888888;
  top: 40%;
  border-radius: 15px;
  width: 300px;

  background-color: #242424;

  .textDiv{
  color: ${secondaryText};
  text-align: center;
  font-size: 16px;
  padding: 15px 15px;
  }

  .buttonDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  }

  .choiceButton{
  width: 50%;
  color: #00dada; 
  font-size: 18px;
  font-weight: 600;
  padding: 5px 10px;
  border: 1px solid #888888;
  background-color: ${mainBackground};
  }

  .left{
  border-bottom-left-radius: 15px;

  }
  .right{
  border-bottom-right-radius: 15px;

  }
`

const Filters = styled.div`
  @keyframes slideIn {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes slideOut {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100%);
      opacity: 0;
    }
  }
  
  ${tw`
      flex
      flex-col
      px-3
      py-1
      justify-center
      mb-2
  `}
  background: ${mainBackground};

  ${props => props.visible && 'animation: slideIn 0.5s ease;'}
  ${props => props.closing && 'animation: slideOut 0.3s ease;'}
`

const FilterTitle = styled.div`
  ${tw`
      text-white
      font-medium
  `}
  font-size: 15px;
  margin-bottom: 15px;
  margin-left: 5px;
`

const FilterSubTitle = styled.div`
  color: white;
  font-size: 13px;
  margin-top: -10px;
  margin-bottom: 15px;
  margin-left: 5px;
`

const StyledSlider = styled(Slider)`
  max-width: 270px;
  .MuiSlider-valueLabel{
    background-color: ${secondaryBackground};
    border-radius: 5px;
    padding: 5px 12px;
  }

  .MuiSlider-rail,
  .MuiSlider-track{
    height: 4px;
  }

  .MuiSlider-thumb{
    &.Mui-active,
    &.Mui-focusVisible{
      box-shadow: inherit;
    }

    width: 20px;
    height: 20px;
    background-color: white;
    border: 3px solid ${mainFucsia};
  }
`

const GET_ITEMS = gql`
query getItemsWarehouse{
  getItemsWarehouse{
    currentItems{
      id
      name
      buyPrice
      expectedSellPrice
      type
      buyDate
      imageUrl
      influencingGoals{
        name
      }
      tags{
        text
      }
      shoe{
        sizeEU
        gender
      }
      watch{
        modelNumber
        serialNumber
      }
      sneaker{
        SKU
        ShoeImage
        ShoeName
      }
    }
    soldItems{
      id
      name
      buyPrice
      expectedSellPrice
      sellPrice
      saleCosts
      sellDate
      type
      buyDate
      imageUrl
      influencingGoals{
        name
      }
      tags{
        text
      }
      shoe{
        sizeEU
        gender
      }
      watch{
        modelNumber
        serialNumber
      }
      sneaker{
        SKU
        ShoeImage
        ShoeName
      }
    }
  }
}
`


const Container = styled.div`
`

const PadContainer = styled.div`
  padding-left: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
`
const PageTitle = styled.div`
  ${tw`
      text-white
      w-full
      flex
      justify-center
  `}
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: 600;
`

const ItemsContainer = styled.div`
  ${tw`
      flex
      flex-col
  `}
  margin-left: 7.5%;
  width: 85%;
`

const SortContainer = styled.div`
  ${tw`
  `}
  margin-left: 20px;
  margin-bottom: 15px;
`

const SortTitle = styled.div`
  color: white;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
`

const SortOption = styled.div`
  ${tw`
      py-1
      px-3
  `}
  font-size: 14px;
  border-radius: 10px;
  background: transparent;
  ${props => props.active ? 'border: 1px solid #00dadacc;' : 'border: 1px solid black;'}
  margin: 5px 5px;
  ${props => props.active && 'color: #00dada;'}
`

const InfoBox = styled.div`
  ${tw`
      flex
      flex-col
      items-center
      py-2
  `}
  margin: 15px 15%;
  border: 2px solid black;
  box-shadow: 0px 1px 1px #00b6b6;
  border-radius: 20px;
  color: white;

  .main{
    font-size: 18px;
  }

  .colorText{
    color: ${secondaryText};
  }

  .colorBlue{
    color: #00dada;
  }

  .secondary{
    font-size: 16px;
  }

  .value{
    font-weight: 600;
  }
`

const MainInfo = styled.div`
  ${tw`
      flex
      flex-col
      items-center
      mb-1
  `}
`

const Info =  styled.div`
  ${tw`
      flex
      flex-col
      items-center
      w-full
  `}
`

const SecondaryInfo = styled.div`
  ${tw`
      w-full
      px-2
      flex
      justify-around
  `}
`

const SelectorRow = styled.div`
  display: flex;
  width: 100%;
  padding-inline: 6%;
  justify-content: space-around;
  margin-bottom: 10px;
`;

const GET_FILTERED_ITEMS = gql`
  query getFilteredItems($filters: filterParams!){
    getFilteredItems(filters: $filters){
      id
      name
      buyPrice
      expectedSellPrice
      type
      buyDate
      imageUrl
      influencingGoals{
        name
      }
      tags{
        text
      }
      shoe{
        sizeEU
        gender
      }
      watch{
        modelNumber
        serialNumber
      }
      sneaker{
        SKU
        ShoeImage
        ShoeName
      }
    }
  }
`

const DELETE_ITEM = gql`
  mutation($itemId: Float!){
    deleteItem(itemId: $itemId)
  }
`

const UNDO_SALE = gql`
  mutation($itemId: Float!){
    undoSale(itemId: $itemId)
  }
`

const ChangingContainer = styled.div`

`

const GET_SPECIFIC_GOALS = gql`
  query getSpecificGoals{
    getSpecificGoals{
      name
    }
  }
`

export default function WarehousePage(){
  const navigate = useNavigate();
  const {loading, error, data: dataItems, refetch} = useQuery(GET_ITEMS, {
    onError: (error) => {
      console.log(error);
    }
  });

  const [cancelItem, {loading : loadingDelete, error: errorDelete, data: dataDelete}] = useMutation(DELETE_ITEM, {
    onError: (error) => {
      setDeleteError(true);
      setTimeout( () => {
        setDeleteError(false);
      }, 1500)
    },
    onCompleted: () => {
      setDeleteCorrect(true);
      setTimeout( () => {
        setDeleteCorrect(false);
      }, 1500)
    }
  });

  const [undoItemSale, {loading : loadingUndo, error: errorUndo, data: dataUndo}] = useMutation(UNDO_SALE, {
    onError: (error) => {
      setUndoError(true);
      setTimeout( () => {
        setUndoError(false);
      }, 1500)
    },
    onCompleted: () => {
      setUndoCorrect(true);
      setTimeout( () => {
        setUndoCorrect(false);
      }, 1500)
    }
  });

  const { data: dataGoals, refetch: refetchGoals} = useQuery(GET_SPECIFIC_GOALS);

  const [specificGoals, setGoals] = React.useState([]);

  React.useEffect( () => {
    if (dataGoals){
      setGoals(dataGoals.getSpecificGoals);
    }
  }, [dataGoals])

  const [deleteCorrect, setDeleteCorrect] = React.useState(false);
  const [deleteError, setDeleteError] = React.useState(false);
  const [undoCorrect, setUndoCorrect] = React.useState(false);
  const [undoError, setUndoError] = React.useState(false);
  const [unsoldItems, setUnsold] = React.useState([]);
  const [soldItems, setSold] = React.useState([]);
  const [unsoldShow, setUnsoldShow] = React.useState([]);
  const [soldShow, setSoldShow] = React.useState([]);
  const [warehouseValue, setWarehouse] = React.useState(0);
  const [wareCost, setWarehouseCost] = React.useState(0);
  const [totalSales, setSales] = React.useState(0);
  const [totalGain, setGains] = React.useState(0);
  const [showInfo, setShow] = React.useState(false);
  const [itemForInfo, setItem] = React.useState(null);
  const [sorting, setSorting] = React.useState('dateDesc')

  React.useEffect( () => {
    if (dataItems && !filtersUsed){
      const {currentItems, soldItems} = dataItems.getItemsWarehouse;
      setUnsold(currentItems);
      setSold(soldItems);
      let totSales = 0;
      let totGain = 0;
      for (const item of soldItems){
        totSales = totSales + item.sellPrice;
        totGain = totGain + (item.sellPrice - item.buyPrice - item.saleCosts);
      }
      setSales(totSales);
      setGains(totGain);
    }
  }, [dataItems])


  React.useEffect( () => {
    if (unsoldItems){
      let warehouse = 0;
      let warehouseCost = 0;
      for (const item of unsoldItems){
        warehouse = warehouse + item.expectedSellPrice;
        warehouseCost = warehouseCost + item.buyPrice;
      }
      setWarehouse(warehouse);
      setWarehouseCost(warehouseCost);
    }
  }, [unsoldItems])
  


  React.useEffect( () => {
    switch(sorting){
      case 'dateAsc':
        setSoldShow(soldItems && soldItems.slice().reverse());
        setUnsoldShow(unsoldItems && unsoldItems.slice().reverse());
        break;
      case 'dateDesc':
        setSoldShow(soldItems);
        setUnsoldShow([...unsoldItems]);
        break;
      case 'priceAsc':
        setSoldShow(soldItems && soldItems.slice().sort((a, b) => a.sellPrice - b.sellPrice));
        setUnsoldShow(unsoldItems && unsoldItems.slice().sort((a, b) => a.buyPrice - b.buyPrice));
        break;
      case 'priceDesc':
        setSoldShow(soldItems && soldItems.slice().sort((a, b) => b.sellPrice - a.sellPrice));
        setUnsoldShow(unsoldItems && unsoldItems.slice().sort((a, b) => b.buyPrice - a.buyPrice));
        break;
      case 'alphDesc':
        setSoldShow(soldItems && soldItems.slice().sort((a, b) => b.name.localeCompare(a.name)));
        setUnsoldShow(unsoldItems && unsoldItems.slice().sort((a, b) => b.name.localeCompare(a.name)));
        break;
      case 'alphAsc':
        setSoldShow(soldItems && soldItems.slice().sort((a, b) => a.name.localeCompare(b.name)));
        setUnsoldShow(unsoldItems && unsoldItems.slice().sort((a, b) => a.name.localeCompare(b.name)));
        break;
      default:
        break;
    }
  }, [sorting, soldItems, unsoldItems]);

  function closeForm(){
    setShow(false);
  }

  function showInfoCard(item){
    setItem(item);
    setShow(true);
  }

  function goSellItem(){
    navigate('/nuovoOggetto');
  }

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smooth scrolling, use 'auto' for instant scrolling
    });
  }

  const [showFilters, setShowFilters] = React.useState(false); 
  const [typeSelections, setTypeSel] = React.useState({
    other: false,
    shoe: false,
    watch: false
  })
  const [expectedRange, setExpectedRange] = React.useState({
    startPrice: null,
    endPrice: null
  });
  const [closing, setClosing] = React.useState(false);
  const [lowerShoeSize, setLowerShoe] = React.useState();
  const [higherShoeSize, setHigherShoe] = React.useState();
  const [nameSearch, setNameSearch] = React.useState("");
  const [modelNumber, setModelNumber] = React.useState();
  const [getFilterItems, {loadingFiltered, errorFiltered, data: dataFiltered}] = useLazyQuery(GET_FILTERED_ITEMS);
  const [tags, setTags] = React.useState("");
  const [showDelete, setShowDelete] = React.useState(false);
  const [showUndo, setShowUndo] = React.useState(false);


  const closeDeleteWindow = useCallback(() => {
    setShowDelete(false);
  })

  const closeUndoWindow = useCallback(() => {
    setShowUndo(false);
  })

  function manualUpdate(dataItems){
    const {currentItems, soldItems} = dataItems.getItemsWarehouse;
    setUnsold(currentItems);
    setSold(soldItems);
    let warehouse = 0;
    let warehouseCost = 0;
    for (const item of currentItems){
      warehouse = warehouse + item.expectedSellPrice;
      warehouseCost = warehouseCost + item.buyPrice;
    }
    let totSales = 0;
    let totGain = 0;
    for (const item of soldItems){
      totSales = totSales + item.sellPrice;
      totGain = totGain + (item.sellPrice - item.buyPrice - item.saleCosts);
    }
    setWarehouse(warehouse);
    setWarehouseCost(warehouseCost);
    setSales(totSales);
    setGains(totGain);
  }

  function cleanFilters(closeBool){
    refetch()
      .then((result) => {
        manualUpdate(result.data);
      })
    setTags("");
    setNameSearch("");
    setExpectedRange({
      startPrice: null,
      endPrice: null
    })
    setTypeSel({
      other: false,
      shoe: false,
      watch: false
    })
    setLowerShoe("Da");
    setHigherShoe("A");
    setModelNumber(null);
    if (closeBool)
      closeFilters();
  }

  function closeFilters(){
    setClosing(true);      
    setTimeout( () => {
      setShowFilters(!showFilters);
      setClosing(false);
    }, 300)
  }

  const [filtersUsed, setFiltersUsed] = React.useState(false);

  function useFilters(event){
    setClosing(true);      
    setTimeout( () => {
      setShowFilters(!showFilters);
      setClosing(false);
    }, 300)

    var itemTypes = [];

    if (typeSelections.other){
      itemTypes.push("other");
    }
    if (typeSelections.shoe){
      itemTypes.push("shoe");
    }
    if (typeSelections.watch){
      itemTypes.push("watch");
    }
    if (!typeSelections.other && !typeSelections.shoe && !typeSelections.watch){
      itemTypes = ["other", "shoe", "watch"];
    }

    var filters = {
      itemTypes: [...itemTypes],
      startExpectedPrice: expectedRange.startPrice || 0,
      endExpectedPrice: expectedRange.endPrice || GRAPHQL_MAX_INT,
      modelNumber: modelNumber || null,
      name: nameSearch || null,
      shoeSizeStart: parseFloat(lowerShoeSize)|| null,
      shoeSizeEnd: parseFloat(higherShoeSize) || null,
      tags: tags ? [...stringToArray(tags)] : null
    }
    try{
      getFilterItems({
        variables: {
          filters: {...filters}
        }
      })
    }
    catch(error){
      console.log(error);
    }
    scrollTop();
    setFiltersUsed(true)
  }

  React.useEffect( () => {
    if(dataFiltered){
      setUnsold([...dataFiltered.getFilteredItems]);
    }
  }, [dataFiltered])


  React.useEffect( () => {
    if(deleteCorrect){
      refetch();
      handleSetAllDataUpdated();
    }
  }, [deleteCorrect]);

  React.useEffect( () => {
    if(undoCorrect){
      refetch();
      handleSetAllDataUpdated();
    }
  }, [undoCorrect]);

  function handleModelNumber(event){
    setModelNumber(event.target.value);
  }

  function changeShowFilters(){
    if (!showFilters){
      setShowFilters(!showFilters);
    }
    else{
      setClosing(true);      
      setTimeout( () => {
        setShowFilters(!showFilters);
        setClosing(false);
      }, 300)
    }
  }

  function changeType(event){
    setTypeSel(prevSel => {
      return({
        ...prevSel,
        [event.target.id]: !prevSel[event.target.id]
      })
    })
  }

  function handleNameSearch(event){
    const {value} = event.target;

    setNameSearch(value);
  }

  function handleTags(event){
    const {value} = event.target;

    setTags(value);
  }

  function goToSale(){
    handleSetItemToSell();
    navigate('/nuovoOggetto');
  }

  function getIcon(string){
    if (string == "shoe") return shoe;
    else if (string == "watch") return watch;
    else if (string == "other") return generic;
    else return null;
  }

  function stringToArray(inputString) {
    // Use the split method to split the string into an array
    // based on the comma and space (", ") delimiter
    var resultArray = inputString.split(", ");

    // Trim whitespace from each element in the array
    resultArray = resultArray.map(function (element) {
        return element.trim();
    });

    return resultArray;
  }

  const { state, dispatch} = useGlobalState();

  const handleToggleDataUpdate = () => {
    dispatch({
      type: actionTypes.TOGGLE_DATA_UPDATE,
      payload: 'warehouseData',
    });
  };

  const handleSetAllDataUpdated = () => {
    dispatch({
      type: actionTypes.SET_ALL_DATA_UPDATED,
    });
  };
  
  const handleSetItemToSell = () => {
    dispatch({
      type: sellActionTypes.SET_ITEM_TO_SELL,
      payload: {
        itemInfo: {...itemForInfo},
      }
    })
  }

  React.useEffect( ( ) => {
    if(state.dataUpdated.warehouseData){
      handleToggleDataUpdate();
      try{
        refetch();
        refetchGoals();
      }
      catch(error){
        console.log(error);
      }
    }
  }, [])

  function deleteItem(){
    cancelItem({
      variables: {
        itemId: itemForInfo.id
      }
    })
    closeDeleteWindow();
  }

  function undoSale(){
    undoItemSale({
      variables: {
        itemId: itemForInfo.id
      }
    })
    closeUndoWindow();
  }

  /**
   * update section
   */

  const [updatingItem, setUpdating] = React.useState(false);
  const [resellingItem, setReselling] = React.useState(false);

  function showUpdate(){
    setUpdating(true);
    setShow(false);
  }

  function showResell(){
    setReselling(true);
    setShow(false);
  }

  function handleChange(){
    refetch();
    handleSetAllDataUpdated();
    setUpdating(false);
  }

  function handleResell(){
    refetch();
    handleSetAllDataUpdated();
    setReselling(false);
  }

  return(
    <Container>
      <HeaderFixed pageTitle="MAGAZZINO"/>
      {!loading && !updatingItem && !resellingItem && <div><Tab.Group>
        <Tab.List className=" navigationWare flex">
          <Tab className={({ selected }) =>
                classNames(
                  'w-full text-md tabCustomWare brWare',
                  'focus:outline-none',
                  selected
                    ? 'warehouseTab font-medium'
                    : 'hover:text-white font-normal'
                )
              }>Attuali</Tab>
          <Tab className={({ selected }) =>
                classNames(
                  'w-full text-md tabCustomWare',
                  'focus:outline-none',
                  selected
                  ? 'warehouseTab font-medium'
                  : 'hover:text-white font-normal'
                )
              }>Precedenti</Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <InfoBox>
              <MainInfo>
                <span className="main colorBlue">Valore Magazzino</span>
                <span className="main value">{warehouseValue}€</span>
              </MainInfo>
              <SecondaryInfo>
                <Info>
                  <span className="secondary colorText">Oggetti</span>
                  <span className="secondary value">{unsoldItems.length}</span>
                </Info>
                <Info>
                  <span className="secondary colorText">Costo</span>
                  <span className="secondary value">{wareCost}€</span>
                </Info>
              </SecondaryInfo>
            </InfoBox>
            {unsoldItems.length > 0 && 
            <div>
              <FiltersContainer>
                <ShowFilters active={showFilters} onClick={changeShowFilters}>
                  {!showFilters ? "Mostra filtri" : "Nascondi filtri"}
                  <img src={filterIcon} />
                </ShowFilters>
              </FiltersContainer>
              {showFilters &&
                <Filters visible={showFilters} closing={closing}>
                  <TypeFilters>
                    <FilterTitle>
                      Tipi
                    </FilterTitle>
                    <TypesContainer>
                      <TypesFiltering type="Generico" state={typeSelections.other} onClickFunction={changeType} id="other"/>
                      <TypesFiltering type="Scarpa" state={typeSelections.shoe} onClickFunction={changeType} id="shoe"/>
                      <TypesFiltering type="Orologio" state={typeSelections.watch} onClickFunction={changeType} id="watch"/>
                    </TypesContainer>
                  </TypeFilters>
                  <FilterTitle>
                    Cerca per nome
                  </FilterTitle>
                  <CenterContainer>
                    <FormField width={longInput} name="Nome oggetto"  inputName="nameSearch" value={nameSearch} handler={handleNameSearch} />
                  </CenterContainer>
                  <FilterTitle>
                    Filtra per tag
                  </FilterTitle>
                  <FilterSubTitle>
                    Inserisci i tag che vuoi considerati per intero, separati da virgola. (es Nike, Sneaker, StockX)
                  </FilterSubTitle>
                  <CenterContainer>
                    <FormField width={longInput} name="Tags"  inputName="tags" value={tags} handler={handleTags} />
                  </CenterContainer>
                  <FilterTitle>
                    Filtra per taglia
                  </FilterTitle>
                  <SliderContainer>
                      <InputContainer>
                        <span>Da</span>
                        <InputIconContainer>
                          <IconNumberContainer>
                            <img src={size_icon} />
                          </IconNumberContainer>
                          <NumberInput
                            type="number"
                            value={lowerShoeSize}
                            onChange={(e) => {setLowerShoe(e.target.value)}}
                          />
                        </InputIconContainer>
                      </InputContainer>
                      <InputContainer>
                        <span>A</span>
                        <InputIconContainer>
                        <IconNumberContainer>
                            <img src={size_icon} />
                          </IconNumberContainer>
                          <NumberInput
                            type="number"
                            value={higherShoeSize}
                            onChange={(e) => {setHigherShoe(e.target.value)}}
                          />
                        </InputIconContainer>
                      </InputContainer>
                    </SliderContainer>
                  {typeSelections.watch && <FilterTitle>
                    Cerca per numero modello
                  </FilterTitle>}
                  {typeSelections.watch && <PadContainer>
                    <FormField width={longInput} name="Numero modello"  inputName="modelNumber" value={modelNumber} handler={handleModelNumber} />
                  </PadContainer>}
                  <FilterTitle>
                    Prezzo a cui vendere
                  </FilterTitle>
                    <SliderContainer>
                      <InputContainer>
                        <span>Min</span>
                        <InputIconContainer>
                          <IconNumberContainer>
                            <EuroSymbolIcon sx={{color: "rgba(255, 255,255, 0.7)"}}/>
                          </IconNumberContainer>
                          <NumberInput
                            type="number"
                            value={expectedRange.startPrice}
                            onChange={(e) => {setExpectedRange(prevFilters => ({...prevFilters,
                            startPrice: parseInt(e.target.value)}))}}
                          />
                        </InputIconContainer>
                      </InputContainer>
                      <InputContainer>
                        <span>Max</span>
                        <InputIconContainer>
                          <IconNumberContainer>
                            <EuroSymbolIcon sx={{color: "rgba(255, 255,255, 0.7)"}}/>
                          </IconNumberContainer>
                          <NumberInput
                            type="number"
                            value={expectedRange.endPrice}
                            onChange={(e) => {setExpectedRange(prevFilters => ({...prevFilters,
                            endPrice: parseInt(e.target.value)}))}}
                          />
                        </InputIconContainer>
                      </InputContainer>
                    </SliderContainer>
                  <div className="flex justify-around px-2">
                    <MainButton active={true} text="Applica filtri" fontSize="12px" onClickFunction={useFilters}/>
                    <MainButton active={true} text="Pulisci filtri" fontSize="12px" onClickFunction={() => {cleanFilters(true)}}/>
                  </div>
                </Filters>}
              </div>
            }
            {unsoldItems.length > 0 && 
            <SortContainer>
              <SortTitle>
                Ordina per
              </SortTitle>
              <RadioGroup value={sorting} onChange={setSorting} className="radioWarehouse">
                <RadioGroup.Option value="priceDesc">
                  {({ checked }) => (
                    <SortOption id="priceDesc" active={checked}>
                    Prezzo: decrescente
                  </SortOption>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option value="priceAsc">
                  {({ checked }) => (
                    <SortOption id="priceAsc" active={checked}>
                      Prezzo: crescente
                    </SortOption>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option value="dateDesc">
                  {({ checked }) => (
                    <SortOption id="dateDesc" active={checked}>
                      Data: più vicina
                    </SortOption>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option value="dateAsc">
                  {({ checked }) => (
                    <SortOption id="dateAsc" active={checked}>
                      Data: più lontana
                    </SortOption>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option value="alphAsc">
                  {({ checked }) => (
                    <SortOption id="alphAsc" active={checked}>
                      Ordine alfabetico
                    </SortOption>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option value="alphDesc">
                  {({ checked }) => (
                    <SortOption id="alphDesc" active={checked}>
                      Alfabetico inverso
                    </SortOption>
                  )}
                </RadioGroup.Option>
              </RadioGroup>
            </SortContainer>}
            {unsoldItems.length > 0 &&
              <PrintItemList purchases={unsoldItems} sales={[]} /> 
            }
          {unsoldItems.length > 0 ?
              <ItemsContainer>
              {unsoldShow.map((item, index) => (
                <ItemWarehouse 
                  item={item}
                  sales = {false}
                  index={index}
                  infoFunc={showInfoCard}
                  sellFunc={goSellItem}
                  key={index}
                />

              ))
              }</ItemsContainer>  :(!filtersUsed ?
              <NoItems>
                <img src={roundAlert} />
                <MessageContainer>
                  <span>Sembra che tu non abbia nessun oggetto in magazzino! Hai comprato qualcosa?</span>
                </MessageContainer>
                <MainButton active={true} text="Aggiungi" onClickFunction={() => {navigate('/nuovoOggetto');}}/>
              </NoItems> : 
              <NoItems>
                <img src={roundAlert} />
                <MessageContainer>
                  <span>Nessun oggetto corrisponde ai filtri indicati.</span>
                </MessageContainer>
                <MainButton active={true} text="Pulisci filtri" onClickFunction={() => {cleanFilters(false)}}/>
              </NoItems>
              )

            }
          </Tab.Panel>
          <Tab.Panel>
              <InfoBox>
              <MainInfo>
                <span className="main colorBlue">Vendite Totali</span>
                <span className="main value">{totalSales}€</span>
              </MainInfo>
              <SecondaryInfo>
                <Info>
                  <span className="secondary colorText">Oggetti</span>
                  <span className="secondary value">{soldItems.length}</span>
                </Info>
                <Info>
                  <span className="secondary colorText">Guadagno</span>
                  <span className="secondary value">{totalGain}€</span>
                </Info>
              </SecondaryInfo>
            </InfoBox>
            { soldItems.length > 0 && <SortContainer>
              <SortTitle>
                Ordina per
              </SortTitle>
              <RadioGroup value={sorting} onChange={setSorting} className="radioWarehouse">
                <RadioGroup.Option value="priceDesc">
                  {({ checked }) => (
                    <SortOption id="priceDesc" active={checked}>
                    Prezzo: decrescente
                  </SortOption>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option value="priceAsc">
                  {({ checked }) => (
                    <SortOption id="priceAsc" active={checked}>
                      Prezzo: crescente
                    </SortOption>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option value="dateDesc">
                  {({ checked }) => (
                    <SortOption id="dateDesc" active={checked}>
                      Data: più vicina
                    </SortOption>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option value="dateAsc">
                  {({ checked }) => (
                    <SortOption id="dateAsc" active={checked}>
                      Data: più lontana
                    </SortOption>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option value="alphAsc">
                  {({ checked }) => (
                    <SortOption id="alphAsc" active={checked}>
                      Ordine alfabetico
                    </SortOption>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option value="alphDesc">
                  {({ checked }) => (
                    <SortOption id="alphDesc" active={checked}>
                      Alfabetico inverso
                    </SortOption>
                  )}
                </RadioGroup.Option>
              </RadioGroup>
            </SortContainer>}
            {soldItems.length > 0 &&
              <PrintItemList purchases={[]} sales={soldItems} /> 
            }
            {soldItems.length > 0 ?
            <ItemsContainer>
              {soldShow.map((item, index) => (
                <ItemWarehouse 
                  item={item}
                  sales = {true}
                  index={index}
                  infoFunc={showInfoCard}
                  key={index}
                />

              ))}  
              </ItemsContainer>:
              <NoItems>
                <img src={roundAlert} />
                <MessageContainer>
                  <p>Sembra che tu non abbia venduto nessun oggetto! Hai venduto qualcosa?</p>
                </MessageContainer>
                <MainButton active={true} text="Aggiungi" onClickFunction={() => {navigate('/nuovoOggetto');}}/>
              </NoItems>
            }
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      
      <Transition.Root show={showInfo} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 z-10 overflow-y-auto" 
          open={showInfo}
          onClose={() => closeForm()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {itemForInfo ? <InfoContainer>
                {!itemForInfo.imageUrl && <img className={!itemForInfo.sneaker && 'iconImage'} src={itemForInfo.sneaker && itemForInfo.sneaker.ShoeImage || getIcon(itemForInfo.type)} />}
                {itemForInfo.imageUrl && <img className={'itemImage'} src={itemForInfo.imageUrl} />}
                <span className="itemName">{itemForInfo.name}</span>
                <InfoGroupTitle>
                  Informazioni generali
                </InfoGroupTitle>
                <InfoRow>
                  <span className="itemBuyPrice">Prezzo di acquisto:</span>
                  <span>{itemForInfo.buyPrice}€</span>
                </InfoRow>
                <InfoRow>
                  <span className="itemExpectedPrice">Aspettativa di vendita:</span>
                  <span>{itemForInfo.expectedSellPrice}€</span>
                </InfoRow>
                {itemForInfo.sellPrice && 
                <InfoRow>
                  <span className="itemSellPrice">Prezzo di vendita:</span>
                  <span>{itemForInfo.sellPrice}€</span>
                </InfoRow>}
                {itemForInfo.sellPrice && 
                <InfoRow>
                  <span className="itemSellPrice">Spese e commissioni:</span>
                  <span>{itemForInfo.saleCosts}€</span>
                </InfoRow>}
                {itemForInfo.sellPrice && 
                <InfoRow>
                  <span className="itemSellPrice">Guadagno:</span>
                  <span>{itemForInfo.sellPrice - itemForInfo.buyPrice - itemForInfo.saleCosts}€</span>
                </InfoRow>}
                {itemForInfo.sellPrice && 
                <InfoRow>
                  <span className="itemSellPrice">Guadagno %:</span>
                  <span>{Math.round((itemForInfo.sellPrice - itemForInfo.buyPrice - itemForInfo.saleCosts) / itemForInfo.sellPrice * 100)}%</span>
                </InfoRow>}
                <InfoRow>
                  <span className="itemBuyDate">Data acquisto:</span>
                  <span>{formatDate(itemForInfo.buyDate)}</span>
                </InfoRow>
                {itemForInfo.sellDate && 
                <InfoRow> 
                  <span className="itemSellDate">Data vendita:</span>
                  <span>{formatDate(itemForInfo.sellDate)}</span>
                </InfoRow>}
                {itemForInfo.type == "shoe" && <InfoGroupTitle>
                  Informazioni scarpa
                </InfoGroupTitle>}
                {itemForInfo.type == "shoe" && 
                <InfoRow>
                  <span className="itemBuyDate">Taglia:</span>
                  <span>{itemForInfo.shoe.sizeEU} {itemForInfo.shoe.gender}</span>
                </InfoRow>}
                {itemForInfo.type == "watch" && <InfoGroupTitle>
                  Informazioni orologio
                </InfoGroupTitle>}
                {itemForInfo.type == "watch" && 
                <InfoRow>
                  <span className="itemBuyDate">Numero modello:</span>
                  <span>{itemForInfo.watch.modelNumber}</span>
                </InfoRow>}
                <OptionsContainer>
                  {!itemForInfo.sellPrice && <OptionWare onClick={goToSale}>
                    <OptionWareIcon src={sell_icon} />
                    <OptionText className="sell">Vendi</OptionText>
                  </OptionWare>}
                  {itemForInfo.sellPrice && <OptionWare onClick={showResell}>
                    <OptionWareIcon src={resell_icon}/>
                    <OptionText className="sell">Vendi simile</OptionText>
                  </OptionWare>}
                  <OptionWare onClick={showUpdate}>
                    <OptionWareIcon src={edit_icon}/>
                    <OptionText>Modifica</OptionText>
                  </OptionWare>
                  {itemForInfo.sellPrice && <OptionWare onClick={() => setShowUndo(true)}>
                    <OptionWareIcon src={undo_transaction}/>
                    <OptionText>Annulla vendita</OptionText>
                  </OptionWare>}
                  <OptionWare onClick={() => setShowDelete(true)}>
                    <OptionWareIcon src={delete_red}/>
                    <OptionText className="delete">Cancella</OptionText>
                  </OptionWare>
                </OptionsContainer>
              </InfoContainer> : <div></div>}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      
      <Transition.Root show={showDelete} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 z-10 overflow-y-auto" 
          open={showDelete}
          onClose={() => closeDeleteWindow()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <ChoiceContainer>
                <div className="textDiv">
                  Sei sicuro di voler eliminare questo oggetto?
                </div>
                <div className="buttonDiv">
                  <button className="choiceButton left" onClick={() => deleteItem()}>Si</button>
                  <button className="choiceButton right" onClick={() => closeDeleteWindow()}>No</button>
                </div>
              </ChoiceContainer>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={showUndo} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 z-10 overflow-y-auto" 
          open={showUndo}
          onClose={() => closeUndoWindow()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <ChoiceContainer>
                <div className="textDiv">
                  Sei sicuro di voler annullare la vendita?
                </div>
                <div className="buttonDiv">
                  <button className="choiceButton left" onClick={() => undoSale()}>Si</button>
                  <button className="choiceButton right" onClick={() => closeUndoWindow()}>No</button>
                </div>
              </ChoiceContainer>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {deleteError && <ErrorPopUp text="Oggetto non cancellato" />}
      {deleteCorrect && <CorrectPopUp text="Oggetto cancellato" />}
      {undoError && <ErrorPopUp text="Vendita non cancellata" />}
      {undoCorrect && <CorrectPopUp text="Vendita cancellata" />}
      </div>}
      
      {loading && <LoadingAnimation />}
      {updatingItem &&
        <ChangingContainer>
          <RowDiv>
            <Arrow onClick={ () => {
              setUpdating(false);
            }}>
              <img src={back_arrow} />
            </Arrow>
            <RegistrationText>
              Modifica oggetto
            </RegistrationText>
          </RowDiv>
          <ChangeItem item={itemForInfo} goals={specificGoals} changeFunction={handleChange}/>
        </ChangingContainer> 
      }
      {
        resellingItem &&
        <ChangingContainer>
          <RowDiv>
            <Arrow onClick={ () => {
              setReselling(false);
            }}>
              <img src={back_arrow} />
            </Arrow>
            <RegistrationText>
              Rivendi oggetto
            </RegistrationText>
          </RowDiv>
          <NewItemForm goals={specificGoals} oldItem={itemForInfo} sellCallback={handleResell}/>
        </ChangingContainer>
      }
      <Navfixed currentPage="warehouse" />
    </Container>
  )
}