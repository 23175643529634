import styled from "styled-components"
import tw from "twin.macro";
import { secondaryText } from "../const.js";
import { useNavigate } from "react-router-dom";
import React from "react"
import {slide as Menu} from 'react-burger-menu'
import './HeaderFixed.css'
import hamburger_icon from "../icons/hamburger.png"
import cross_icon from "../icons/cross.png"
import data_icon from "../icons/data.png"
import taxes_icon from "../icons/taxes.png"
import guide_icon from "../icons/guide.png"
import customer_icon from "../icons/customer.png"
import document_icon from "../icons/document_icon.png"
import email_icon from "../icons/email.png"
import discord_icon from"../icons/discord_icon.png"
import expense_icon from"../icons/expense.png"
import finix_icon from "../logos/finix_logo.png"

const HeaderContainer = styled.div`
  width: 100%;
  height: 45px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  margin-bottom: 5px;
  z-index: 50;

  .customMenu{
    background: black;
    color: white;
    top: 0;
    left: 0;
    overflow-y: hidden;
  }
`
const PageTitle = styled.div`
  ${tw`
  `}
  color: ${secondaryText};
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  top: 6px;
  left: 60px;
`

const HeaderOption = styled.div`
  ${tw`
      px-6
  `}
  color: ${secondaryText};
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  top: 15px;
  left: 35px;
`

const Options = styled.div`
  ${tw`
      flex
      flex-col
      py-8
  `}

  margin-top: 80px;
`

const OptionContainer = styled.div`
  padding: 10px 0px;
  ${props => props.active ? 'border-bottom: 2px solid #00dada;' : 'border-bottom: 2px solid white;'}
  display: flex;
  align-items: center;
  justify-content: start;
  color: white;
  font-size: 18px;
  margin-bottom: 20px;

  img{
    width: 22px;
    height: 22px;
    margin-right: 20px;
    margin-left: 25px
  }

  span{
    margin-left: 10px;
  }

  .color{
    color: #00dada;
  }
`

const ContactsContainer = styled.div`
  ${tw`
      px-4
  `}

  position: absolute;
  bottom: 30px;
`

const ContactsTitle = styled.div`
  color: ${secondaryText};
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
`

const ContactLink = styled.div`
  ${tw`
      flex
      justify-start
  `}
  font-size: 16px;
  color: ${secondaryText};
  align-items: center;


  img{
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
`

const ContactName = styled.span`
`

const IconContainer = styled.div`
  width: 45px;
  height: 45px;
  position: absolute;
  right: 10px;
`



export default function HeaderFixed(props){
  const navigate = useNavigate();
  const {pageTitle, menuPage} = props;
  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = React.useState(null);
  
    React.useEffect(() => {
      let lastScrollY = window.pageYOffset;
  
      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (direction !== scrollDirection && (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener("scroll", updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener("scroll", updateScrollDirection); // clean up
      }
    }, [scrollDirection]);
  
    return scrollDirection;
  };
  
  const scrollDirection = useScrollDirection();
    
  return (
    <HeaderContainer className={`sticky ${ scrollDirection === "down" ? "-top-24" : "top-0"} transition-all duration-500`}>
      <PageTitle>{pageTitle}</PageTitle>
      <Menu width={"75%"}customCrossIcon={ <img id="cross" src={cross_icon} /> } customBurgerIcon={ <img src={hamburger_icon} alt="Menu icon" />} className="customMenu">
        <HeaderOption id="headerMenu">
          MENU
        </HeaderOption>
        <Options>
          <OptionContainer active={menuPage === "DATA"} onClick={ () => navigate('/data')}>
            <img src={data_icon} alt="Data icon"/>
            <span>DATI</span>
          </OptionContainer>
          <OptionContainer active={menuPage === "EXPENSE"} onClick={ () => navigate('/spese')}>
            <img src={expense_icon} alt="Expense icon"/>
            <span>SPESE</span>
          </OptionContainer>
          <OptionContainer active={menuPage === "TAXES"} onClick={ () => navigate('/tasse')}>
            <img src={taxes_icon} alt="Taxes icon"/>
            <span>TASSE</span>
          </OptionContainer>
          <OptionContainer active={menuPage === "DOCUMENTS"} onClick={ () => navigate('/documenti')}>
            <img src={document_icon} alt="Documents icon"/>
            <span>DOCUMENTI</span>
          </OptionContainer>
          <OptionContainer active={menuPage === "PROFILE"} onClick={ () => navigate('/profilo')}>
            <img src={customer_icon} alt="Customer icon"/>
            <span>PROFILO</span>
          </OptionContainer>
          <OptionContainer active={menuPage === "GUIDE"} onClick={ () => navigate('/guida')}>
            <img src={guide_icon} alt="Guide icon"/>
            <span>GUIDA</span>
          </OptionContainer>
        </Options>
        <ContactsContainer>
          <ContactsTitle>
            Contattaci su
          </ContactsTitle>
          <ContactLink>
            <img src={discord_icon} alt="Discord icon"/>
            <ContactName>FinixDiscord</ContactName>
          </ContactLink>
          <ContactLink>
            <img src={email_icon} alt="Email icon"/>
            <ContactName>support.finix@gmail.com</ContactName>
          </ContactLink>
        </ContactsContainer>
      </Menu>
      <IconContainer onClick={() => navigate('/home')}>
        <img src={finix_icon} alt="Finix icon"/>
      </IconContainer>
    </HeaderContainer>
  );
}