import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {React, useState, useEffect, Fragment, useCallback, useRef} from "react";
import styled from "styled-components"
import tw from "twin.macro";
import ProgressBar from "@ramonak/react-progress-bar"
import './css_files/ObjectivesPage.css'
import { getGoalName, getGoalType, goalTypes, longInput, mainBackground, secondaryText } from "../const";
import MainButton from "../components/MainButton";
import { Dialog, Transition } from "@headlessui/react";
import FormField from "../components/FormField";
import CSelector from "../components/CSelector";
import ErrorPopUp from "../components/ErrorPopUp";
import CorrectPopUp from "../components/CorrectPopUp";
import Navfixed from "../components/Navfixed";
import HeaderFixed from "../components/HeaderFixed";
import LoadingAnimation from "../components/LoadingAnimation";
import deleteIcon from "../icons/delete.png"
import { useGlobalState, actionTypes } from "../GlobalStateContext";
import back_arrow from "../icons/back_arrow.png"

const Arrow = styled.div`
  width: 30px;
  height: 30px;
  margin-top: 10px;
  z-index: 2;
  margin-left: 10px;
`

const PageContainer = styled.div`
  padding-bottom: 20px;
`

const FormContainer = styled.div`
  ${tw`
      flex
      flex-col
      items-center
      py-7
      px-5
  `}
  border-radius: 20px;
  background: #242424;
  color: white;
  position: fixed;
  top: 15%;
`

const FormTitle = styled.div`
  color: white;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 40px;
`

const RegistrationText = styled.span`
  ${tw`
      text-white
      flex
      w-full
      justify-center
  `}
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 35px;
`

const GoalContainer = styled.div`
  ${tw`
  
  `}
  color: white;
  margin-bottom: 50px;
  margin-top: 20px;
`

const GoalTitle = styled.div`
  ${tw`

  `}
  color: white;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 15px;
  margin-left: 10px;
`

const Goals = styled.div`
  ${tw`
  `}
  background-color: #242424;
`

const BarText = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  .goalName{
    padding-left: 20px;
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
  }

  img{
    width: 18px;
    height: 18px;
    z-index: 10;
  }

  .completato{
    position: absolute;
    right: 15px;
    font-size: 14px;
  }
`

const Goal = styled.div`
  ${tw`
    py-5
  `}
  position:relative;
  border-bottom: 2px solid #181818;
  padding-bottom: 10px;

`

const BarValue = styled.div`
  ${tw`
      flex
      items-center
  `}
  position: relative;
  padding-left: 10px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 5px;

  .percent{
    position: absolute;
    right: 15px;
    font-size: 20px;
    font-weight: 600;
  }
`

const BarGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;

  .barCompleted{
    background: #7affffaa;
    border: 2px solid #00dada;
    width: ${props => props.width ? props.width : '0%'};
    border-radius: 5px;
    height: 20px;
  }
`

const NumericValues = styled.div`
  ${tw`
      flex
      justify-between
      items-center
      w-full
      px-8
  `}

  margin-top: 5px;
  .goalValue{
    font-size: 14px;
    color: white;
  }

  .currentValue{
    font-size: 14px;
    color: #00dada;
  }
`

const GET_GOALS = gql`
  query getGoals{
    getGoals{
      generalGoals{
        name
        currentValue
        goalValue
        type
      }
      specificGoals{
        name
        currentValue
        goalValue
        type
      }
    }
  }
`

const NEW_GOAL = gql`
  mutation createGoal($goalInfo: NewGoalArgs!){
    createGoal(goalInfo: $goalInfo){
      generalGoals{
        name
        currentValue
        goalValue
        type
      }
      specificGoals{
        name
        currentValue
        goalValue
        type
      }
    }
  }
`

const DELETE_GOAL = gql`
  mutation deleteGoal($goalName: String!){
    deleteGoal(goalName: $goalName){
      generalGoals{
        name
        currentValue
        goalValue
        type
      }
      specificGoals{
        name
        currentValue
        goalValue
        type
      }
    }
  }
`

const SHOW_GOAL_ITEMS = gql`
    query getItemsFromGoal($goalName: String!){
      getItemsFromGoal(goalName: $goalName){
        buyPrice
        sellPrice
        saleCosts
        name
        sellDate
      }
    }
`


const ChoiceContainer = styled.div`
${tw`
flex
flex-col
items-center
fixed
`}
border: 1px solid #888888;
top: 40%;
border-radius: 15px;
width: 300px;

background-color: #242424;

.textDiv{
color: ${secondaryText};
text-align: center;
font-size: 16px;
padding: 15px 15px;
}

.buttonDiv{
display: flex;
justify-content: center;
align-items: center;
width: 100%;
}

.choiceButton{
width: 50%;
color: #00dada; 
font-size: 18px;
font-weight: 600;
padding: 5px 10px;
border: 1px solid #888888;
background-color: ${mainBackground};
}

.left{
border-bottom-left-radius: 15px;

}
.right{
border-bottom-right-radius: 15px;

}
`

const TransactionList = styled.div`
  ${tw`
      flex
      flex-col
      w-full
      px-2
      items-center
    `}
`
const Transaction = styled.div`
  ${tw`
      py-2
      flex
      flex-col
      justify-start
    `}
  width: 95%;
  font-size: 16px;
  color: white;
  border-bottom: 1px solid #EFEFEF;
`
const ItemName = styled.div`
  ${tw`

    `}
`
const TransactionValue = styled.div`
  ${tw`

    `}

  color: ${props => props.color ? "#61DE70;" : "#de6161;"}
`

const PrimaryRow = styled.div`
    ${tw`
        flex
        justify-between
      `}
`
const SecondaryRow = styled.div`
    color: #EFEFEF;
    font-size: 10px;  
`

export default function ObjectivesPage(){
  const {loading, error, data, refetch} = useQuery(GET_GOALS);
  const [getItemsFromGoal, {loading: loadingItems, error: errorItems, data: dataItems}] = useLazyQuery(SHOW_GOAL_ITEMS, {
    onError: (error) => {
      console.log(error);
    }
  });
  const [newGoal, {loadingGoal, errorGoal, data: dataGoal}] = useMutation(NEW_GOAL, {
    onError: (e) => {
      setError(true);
      setTimeout( () => {
        setError(false);
      }, 1500);
    },
    onCompleted: () => {
      setCorrect(true);
      setTimeout( () => {
        setCorrect(false);
        handleSetAllDataUpdated();
      }, 1500);
    }
  });
  const [deleteObj, {loadingDelete, errorDelete, data: dataDelete}] = useMutation(DELETE_GOAL, {
    onError: (e) => {
      setDeleteUncorrect(true);
      setTimeout( () => {
        setDeleteUncorrect(false);
      }, 1500);
    },
    onCompleted: () => {
      setDeleteCorrect(true);
      setTimeout( () => {
        setDeleteCorrect(false);
        handleSetAllDataUpdated();
      }, 1500);
    }
  });


  const [goalInfo, setGoalInfo] = useState({
    name: null,
    goalValue: null
  });

  const [personalGoals, setPersonals] = useState([]);

  const [monthlyGain, setMonthlyGain] = useState(null);
  const [monthlySales, setMonthlySales] = useState(null);
  const [yearlyGain, setYearlyGain] = useState(null);
  const [yearlySales, setYearlySales] = useState(null);
  const [showForm, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [type, setType] = useState(goalTypes[0]);
  const [fixedName, setFixed] = useState(null);
  const [errorMessage, setError] = useState(false);
  const [correctMessage, setCorrect] = useState(false);
  const [goalToDelete, setGoalToDelete] = useState("");
  const [deleteCorrect, setDeleteCorrect] = useState(false);
  const [deleteUncorrect, setDeleteUncorrect] = useState(false);
  const [goalToShow, setGoalToShow] = useState(null)
  const [goaLItemsToShow, setGoalItemsToShow] = useState([])
  const goalRef = useRef()

  useEffect( () => {
    if(data){
      const {generalGoals, specificGoals} = data.getGoals;
      
      const monGain = generalGoals.find(goal => goal.name === "monthlyGain");
      monGain ? setMonthlyGain({
        currentValue : monGain.currentValue,
        goalValue : monGain.goalValue,
        width: `${Math.min(Math.floor((monGain.currentValue / monGain.goalValue) * 100), 100)}%`
      }) : setMonthlyGain(null);

      const monSales = generalGoals.find(goal => goal.name === "monthlySales");
      monSales ? setMonthlySales({
        currentValue : monSales.currentValue,
        goalValue : monSales.goalValue,
        width: `${Math.min(Math.floor((monSales.currentValue / monSales.goalValue) * 100), 100)}%`
      }) : setMonthlySales(null);

      const yearGain = generalGoals.find(goal => goal.name === "yearlyGain");
      yearGain ? setYearlyGain({
        currentValue : yearGain.currentValue,
        goalValue : yearGain.goalValue,
        width: `${Math.min(Math.floor((yearGain.currentValue / yearGain.goalValue) * 100), 100)}%`
      }) : setYearlyGain(null);

      const yearSales = generalGoals.find(goal => goal.name === "yearlySales");
      yearSales ? setYearlySales({
        currentValue : yearSales.currentValue,
        goalValue : yearSales.goalValue,
        width: `${Math.min(Math.floor((yearSales.currentValue / yearSales.goalValue) * 100), 100)}%`
      }) : setYearlySales(null);

      setPersonals([...specificGoals]);
    }
  }, [data])

  useEffect( () => {
    if(dataGoal){
      const {generalGoals, specificGoals} = dataGoal.createGoal;
      
      const monGain = generalGoals.find(goal => goal.name === "monthlyGain");
      monGain ? setMonthlyGain({
        currentValue : monGain.currentValue,
        goalValue : monGain.goalValue,
        width: `${Math.min(Math.floor((monGain.currentValue / monGain.goalValue) * 100), 100)}%`
      }) : setMonthlyGain(null);

      const monSales = generalGoals.find(goal => goal.name === "monthlySales");
      monSales ? setMonthlySales({
        currentValue : monSales.currentValue,
        goalValue : monSales.goalValue,
        width: `${Math.min(Math.floor((monSales.currentValue / monSales.goalValue) * 100), 100)}%`
      }) : setMonthlySales(null);

      const yearGain = generalGoals.find(goal => goal.name === "yearlyGain");
      yearGain ? setYearlyGain({
        currentValue : yearGain.currentValue,
        goalValue : yearGain.goalValue,
        width: `${Math.min(Math.floor((yearGain.currentValue / yearGain.goalValue) * 100), 100)}%`
      }) : setYearlyGain(null);

      const yearSales = generalGoals.find(goal => goal.name === "yearlySales");
      yearSales ? setYearlySales({
        currentValue : yearSales.currentValue,
        goalValue : yearSales.goalValue,
        width: `${Math.min(Math.floor((yearSales.currentValue / yearSales.goalValue) * 100), 100)}%`
      }) : setYearlySales(null);

      setPersonals([...specificGoals]);
    }
  }, [dataGoal])

  useEffect( () => {
    if(dataDelete){
      const {generalGoals, specificGoals} = dataDelete.deleteGoal;
      
      const monGain = generalGoals.find(goal => goal.name === "monthlyGain");
      monGain ? setMonthlyGain({
        currentValue : monGain.currentValue,
        goalValue : monGain.goalValue,
        width: `${Math.min(Math.floor((monGain.currentValue / monGain.goalValue) * 100), 100)}%`
      }) : setMonthlyGain(null);

      const monSales = generalGoals.find(goal => goal.name === "monthlySales");
      monSales ? setMonthlySales({
        currentValue : monSales.currentValue,
        goalValue : monSales.goalValue,
        width: `${Math.min(Math.floor((monSales.currentValue / monSales.goalValue) * 100), 100)}%`
      }) : setMonthlySales(null);

      const yearGain = generalGoals.find(goal => goal.name === "yearlyGain");
      yearGain ? setYearlyGain({
        currentValue : yearGain.currentValue,
        goalValue : yearGain.goalValue,
        width: `${Math.min(Math.floor((yearGain.currentValue / yearGain.goalValue) * 100), 100)}%`
      }) : setYearlyGain(null);

      const yearSales = generalGoals.find(goal => goal.name === "yearlySales");
      yearSales ? setYearlySales({
        currentValue : yearSales.currentValue,
        goalValue : yearSales.goalValue,
        width: `${Math.min(Math.floor((yearSales.currentValue / yearSales.goalValue) * 100), 100)}%`
      }) : setYearlySales(null);

      setPersonals([...specificGoals]);
    }
  }, [dataDelete])

  useEffect( () => {
    if(dataItems){
      let itemValues = []
      for (const item of dataItems.getItemsFromGoal) {
        const newObj = {}; // Create a new object for each item
        newObj.name = item.name; // Save the name property
        newObj.sellDate = item.sellDate;
      
        // Conditional assignment of the value property
        if (goalToShow.type === "sales") {
          newObj.value = item.sellPrice;
        } else {
          newObj.value = item.sellPrice - item.buyPrice - item.saleCosts;
        }
      
        itemValues.push(newObj); // Add the object to the array
      }

      setGoalItemsToShow([...itemValues])
    }
  }, [dataItems])

  

  function setUpForm(newName, newType){
    setGoalInfo(prevGoal => ({
      ...prevGoal,
      name: newName ? newName : prevGoal.name
    }));
    setType(newType);
    setFixed(newName);
    setShow(true);
  }

  function createGoal(event){
    event.preventDefault()
    try{
      newGoal({
        variables: {
          goalInfo: {
            name: fixedName ? getGoalName(fixedName) : goalInfo.name,
            goalValue: parseFloat(goalInfo.goalValue),
            type: getGoalType(type)
          }
        }
      })
    }
    catch(error){
      console.log("errore!!", error)
    }
    closeForm();
  }

  function closeForm(){
    setType(goalTypes[0]);
    setGoalInfo({
      goalValue: null,
      name: null
    });
    setFixed(null);
    setShow(false);
  }

  function handleInput(event){
    const {name, value} = event.target;
    setGoalInfo( prevGoal => {
      return(
        {
          ...prevGoal,
          [name]: value
        }
      )
    });
  }

  const showDeleteChoice = useCallback((goalToEliminate) => {

    setShowDelete(true);
    setGoalToDelete(goalToEliminate);
  }
  )

  const closeDeleteWindow = useCallback(() => {
    setGoalToDelete("");
    setShowDelete(false);
  })

  const deleteGoal = useCallback(() => {
    try{
      deleteObj({
        variables: {
          goalName: goalToDelete
        }
      })
      
    }
    catch(error){
      console.log(error);
    }
    closeDeleteWindow();
  });

  
  const loadGoalItems = useCallback((goalName, goalType, goalValue, goalGoal) => {
    try{
      getItemsFromGoal({
        variables: {
          goalName: goalName
        }
      })
      setGoalToShow({
        name: goalName,
        type: goalType,
        value: goalValue,
        goalValue: goalGoal
      })
    } catch(error){
      console.log(error);
    }
  })

  const { state, dispatch} = useGlobalState();

  const handleToggleDataUpdate = () => {
    dispatch({
      type: actionTypes.TOGGLE_DATA_UPDATE,
      payload: 'goalData',
    });
  };

  const handleSetAllDataUpdated = () => {
    dispatch({
      type: actionTypes.SET_ALL_DATA_UPDATED,
    });
  };

  useEffect( ( ) => {
    if(state.dataUpdated.goalData){
      handleToggleDataUpdate();
      try{
        refetch();
      }
      catch(error){
        console.log(error);
      }
    }
  }, [])

  return(
    <PageContainer>
      <HeaderFixed pageTitle="OBIETTIVI"/>
      {!loading ? <div>
        {!goalToShow && <div>

        <GoalContainer>
        <GoalTitle>
          Obiettivi predefiniti
        </GoalTitle>
        <Goals>
          <Goal onClick={() => monthlyGain && loadGoalItems("monthlyGain", "gain", monthlyGain.currentValue, monthlyGain.goalValue)}>
            <BarText>
              <span className="goalName">Guadagno mensile</span>
              {monthlyGain && <img src={deleteIcon} onClick={(event) => {event.stopPropagation(); showDeleteChoice("monthlyGain")}}/>}
              {monthlyGain && <span className="completato">Completato</span>}
            </BarText>
            {monthlyGain ?
              <BarGroup width={monthlyGain && monthlyGain.width}>
                <BarValue>
                  <ProgressBar
                    className="wrapper"
                    barContainerClassName="barContainer"
                    completedClassName="barCompleted"
                    isLabelVisible={false}
                  />
                  <span className="percent">{monthlyGain.width}</span>
                </BarValue>
                <NumericValues>
                  <span className="currentValue">Raggiunto: {monthlyGain.currentValue}€</span>
                  <span className="goalValue">Obiettivo: {monthlyGain.goalValue}€</span>
                </NumericValues>
              </BarGroup> :

              <MainButton text="Fissa obiettivo" active={true} fontSize="14px" onClickFunction={() => {setUpForm("Guadagno mensile", "Guadagno")}}/>
              }
          </Goal>
          <Goal onClick={() => monthlySales && loadGoalItems("monthlySales", "sales", monthlySales.currentValue, monthlySales.goalValue)}>
            <BarText>
              <span className="goalName">Vendite mensili</span>
              {monthlySales && <img src={deleteIcon} onClick={(event) => {event.stopPropagation(); showDeleteChoice("monthlySales")}}/>}
              {monthlySales && <span className="completato">Completato</span>}
            </BarText>
            {monthlySales ?
              <BarGroup width={monthlySales && monthlySales.width}>
                <BarValue>
                  <ProgressBar
                    className="wrapper"
                    barContainerClassName="barContainer"
                    completedClassName="barCompleted"
                    isLabelVisible={false}
                  />
                  <span className="percent">{monthlySales.width}</span>
                </BarValue>
                <NumericValues>
                  <span className="currentValue">Raggiunto: {monthlySales.currentValue}€</span>
                  <span className="goalValue">Obiettivo: {monthlySales.goalValue}€</span>
                </NumericValues>              
              </BarGroup> :

              <MainButton text="Fissa obiettivo" active={true} fontSize="14px" onClickFunction={() => {setUpForm("Vendite mensili", "Totale")}}/>
              }
          </Goal>
          <Goal onClick={() => yearlyGain && loadGoalItems("yearlyGain", "gain", yearlyGain.currentValue, yearlyGain.goalValue)}>
            <BarText>
              <span className="goalName">Guadagno annuale</span>
              {yearlyGain && <img src={deleteIcon} onClick={(event) => {event.stopPropagation(); showDeleteChoice("yearlyGain")}}/>}
              {yearlyGain && <span className="completato">Completato</span>}
            </BarText>
            {yearlyGain ?
              <BarGroup width={yearlyGain && yearlyGain.width}>
                <BarValue>
                  <ProgressBar
                    className="wrapper"
                    barContainerClassName="barContainer"
                    completedClassName="barCompleted"
                    isLabelVisible={false}
                  />
                  <span className="percent">{yearlyGain.width}</span>
                </BarValue>
                <NumericValues>
                  <span className="currentValue">Raggiunto: {yearlyGain.currentValue}€</span>
                  <span className="goalValue">Obiettivo: {yearlyGain.goalValue}€</span>
                </NumericValues>              
              </BarGroup> :

              <MainButton text="Fissa obiettivo" active={true} fontSize="14px" onClickFunction={ () => {setUpForm("Guadagno annuale", "Guadagno")}}/>
              }
          </Goal>
          <Goal onClick={() => yearlySales && loadGoalItems("yearlySales", "sales", yearlySales.currentValue, yearlySales.goalValue)}>
            <BarText>
              <span className="goalName">Vendite annuali</span>
              {yearlySales && <img src={deleteIcon} onClick={(event) => {event.stopPropagation(); showDeleteChoice("yearlySales")}}/>}
              {yearlySales && <span className="completato">Completato</span>}
            </BarText>
            {yearlySales ?
              <BarGroup width={yearlySales && yearlySales.width}>
                <BarValue>
                  <ProgressBar
                    className="wrapper"
                    barContainerClassName="barContainer"
                    completedClassName="barCompleted"
                    isLabelVisible={false}
                  />
                  <span className="percent">{yearlySales.width}</span>
                </BarValue>
                <NumericValues>
                  <span className="currentValue">Raggiunto: {yearlySales.currentValue}€</span>
                  <span className="goalValue">Obiettivo: {yearlySales.goalValue}€</span>
                </NumericValues>              
              </BarGroup> :

              <MainButton text="Fissa obiettivo" active={true} fontSize="14px" onClickFunction={() => {setUpForm("Vendite annuali", "Totale")}}/>
              }
          </Goal>
        </Goals>
      </GoalContainer>
      <GoalContainer>
        <GoalTitle>
          Obiettivi personali
        </GoalTitle>
      {personalGoals.length > 0 &&
          <Goals>
            {personalGoals.map( (goal, index) => (
              <Goal key={index} onClick={() => loadGoalItems(goal.name, goal.type, goal.currentValue, goal.goalValue)}>
              <BarText>
                <span className="goalName">{goal.name}</span>
                <img src={deleteIcon} onClick={(event) => {event.stopPropagation(); showDeleteChoice(goal.name)}}/>
                <span className="completato">Completato</span>
              </BarText>
                <BarGroup width={`${Math.min(Math.floor((goal.currentValue / goal.goalValue) * 100), 100)}%`}>
                  <BarValue>
                    <ProgressBar
                      className="wrapper"
                      barContainerClassName="barContainer"
                      completedClassName="barCompleted"
                      isLabelVisible={false}
                    />
                    <span className="percent">{`${Math.min(Math.floor((goal.currentValue / goal.goalValue) * 100), 100)}%`}</span>
                  </BarValue>
                  <NumericValues>
                    <span className="currentValue">Raggiunto: {goal.currentValue}€</span>
                    <span className="goalValue">Obiettivo: {goal.goalValue}€</span>
                  </NumericValues>                
                </BarGroup>
            </Goal>
            ))}
          </Goals>
      }
      <MainButton text="Crea obiettivo" active={true} fontSize="14px" onClickFunction={() => {setShow(true)}}/>
      </GoalContainer>
      <Transition.Root show={showForm} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 z-10 overflow-y-auto" 
          open={showForm}
          onClose={() => closeForm()}
          initialFocus={goalRef}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <FormContainer>
                <FormTitle ref={goalRef}>Definisci obiettivo</FormTitle>
                <FormField width={"260px"} name="Nome obiettivo" inputName="name" value={goalInfo.name} handler={handleInput} fontDim="14px" type="text" readonlyF={true} tabIndex="-1"/>
                <FormField width={"260px"} name="Valore obiettivo" inputName="goalValue" value={goalInfo.goalValue} handler={handleInput} fontDim="14px" type="number"/>
                <CSelector options={goalTypes} val={type} func={setType}/>
                <MainButton active={true} text="Crea obiettivo" fontSize="14px" onClickFunction={(e) => createGoal(e)}/>
              </FormContainer>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root> 
      <Transition.Root show={showDelete} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 z-10 overflow-y-auto" 
          open={showDelete}
          onClose={() => closeDeleteWindow()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <ChoiceContainer>
                <div className="textDiv">
                  Sei sicuro di voler eliminare questo obiettivo?
                </div>
                <div className="buttonDiv">
                  <button className="choiceButton left" onClick={() => deleteGoal()}>Si</button>
                  <button className="choiceButton right" onClick={() => closeDeleteWindow()}>No</button>
                </div>
              </ChoiceContainer>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
        </div>}
      {goalToShow && <div>
        <GoalContainer>
          <Arrow onClick={ () => {
            setGoalToShow(null)
            setGoalItemsToShow([])
          }}>
            <img src={back_arrow} />
          </Arrow>
          <GoalTitle>Obiettivo: {goalToShow.name}</GoalTitle>
          <BarGroup width={`${Math.min(Math.floor((goalToShow.value / goalToShow.goalValue) * 100), 100)}%`}>
                  <BarValue>
                    <ProgressBar
                      className="wrapper"
                      barContainerClassName="barContainer"
                      completedClassName="barCompleted"
                      isLabelVisible={false}
                    />
                    <span className="percent">{`${Math.min(Math.floor((goalToShow.value / goalToShow.goalValue) * 100), 100)}%`}</span>
                  </BarValue>
                  <NumericValues>
                    <span className="currentValue">Raggiunto: {goalToShow.value}€</span>
                    <span className="goalValue">Obiettivo: {goalToShow.goalValue}€</span>
                  </NumericValues>                
                </BarGroup>
          <TransactionList>
            {goaLItemsToShow.length > 0 && goaLItemsToShow.map((item, index) => (
              <Transaction key={index}>
                <PrimaryRow>
                  <ItemName>{item.name}</ItemName>
                  <TransactionValue color={item.value > 0}>{item.value > 0 ? `+${item.value}€` : `${item.value}`}</TransactionValue>
                </PrimaryRow>
                <SecondaryRow>
                  {item.sellDate}
                </SecondaryRow>
              </Transaction>
            ))}
          </TransactionList>
        </GoalContainer>
        </div>}
      </div>
      :
      
      <LoadingAnimation />}
      {errorMessage && <ErrorPopUp text="Obiettivo non salvato" />}
      {correctMessage && <CorrectPopUp text="Obiettivo salvato" />}
      {deleteUncorrect && <ErrorPopUp text="Obiettivo non cancellato" />}
      {deleteCorrect && <CorrectPopUp text="Obiettivo cancellato" />}
      <Navfixed currentPage="goal" />
    </PageContainer>
  )
}